const JsSlider = {
	init () {
		// Destinations Slider
		if ($('.c-slider--destinations').length) {
			$('.c-slider--destinations').slick({
				// Lazyload all the things
				lazyLoad: 'ondemand',
				slidesToShow: 3,
				slidesToScroll: 3,
				arrows: true,
				prevArrow: '<div class="slick-prev" aria-hidden="true"><div>',
				nextArrow: '<div class="slick-next" aria-hidden="true"><div>',
				dots: false,
				draggable: true,
				pauseOnFocus: false,
				pauseOnHover: true,
				fade: false,
				infinite: false,
				cssEase: 'linear',
				autoplay: true,
				autoplaySpeed: 3000,
				responsive: [
					{
						breakpoint: 768,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 1,
						}
					}
				]
			});
		}
		// News Slider
		if ($('.c-slider--news').length) {
			$('.c-slider--news').slick({
				// Lazyload all the things
				lazyLoad: 'ondemand',
				slidesToShow: 2,
				slidesToScroll: 2,
				arrows: true,
				prevArrow: '<div class="slick-prev" aria-hidden="true"><div>',
				nextArrow: '<div class="slick-next" aria-hidden="true"><div>',
				dots: false,
				draggable: true,
				pauseOnFocus: false,
				pauseOnHover: true,
				fade: false,
				infinite: false,
				cssEase: 'linear',
				autoplay: true,
				autoplaySpeed: 3000,
				responsive: [
					{
						breakpoint: 768,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1,
							fade: true
						}
					}
				]
			});
		}
		// Products Slider
		if ($('.c-slider--products').length) {
			$('.c-slider--products').slick({
				// Lazyload all the things
				lazyLoad: 'ondemand',
				slidesToShow: 3,
				slidesToScroll: 3,
				arrows: true,
				prevArrow: '<div class="slick-prev" aria-hidden="true"><div>',
				nextArrow: '<div class="slick-next" aria-hidden="true"><div>',
				dots: false,
				draggable: true,
				pauseOnFocus: false,
				pauseOnHover: true,
				fade: false,
				infinite: false,
				cssEase: 'linear',
				autoplay: true,
				autoplaySpeed: 3000,
				responsive: [
					{
						breakpoint: 769,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 2,
						}
					}
				]
			});
		}
		// Accrediations Slider
		if ($('.c-slider--accreditations').length) {
			$('.c-slider--accreditations').slick({
				// Lazyload all the things
				lazyLoad: 'ondemand',
				slidesToShow: 7,
				slidesToScroll: 1,
				arrows: false,
				dots: true,
				draggable: true,
				pauseOnFocus: false,
				pauseOnHover: true,
				fade: false,
				infinite: false,
				cssEase: 'linear',
				autoplay: false,
				autoplaySpeed: 3000,
				responsive: [
					{
						breakpoint: 768,
						settings: {
							slidesToShow: 4,
							slidesToScroll: 1,
						}
					},
					{
						breakpoint: 640,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 2,
						}
					}
				]
			});
		}
	}
};
export default JsSlider;