const JsCookieNotice = {
  init() {
    // Open Cookie Window on Click
    const cookieNotice = document.querySelector(".open-cookie-consent");
    if (cookieNotice) {
      cookieNotice.addEventListener("click", () => {
        const cookieConsent = document.getElementById("elc-cookie-consent");
        cookieConsent.classList.remove("elc-hidden");
      });
    }
  },
};
export default JsCookieNotice;
