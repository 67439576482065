const JsNotice = {
  init(Cookies) {
    // Handle Modal and header block with a cookie
    // https://github.com/js-cookie/js-cookie
    if (Cookies.get("dismissed_urgent_notice") != "true") {
      const urgentNotice = document.querySelector(".o-urgent-notice");
      const dismissNotice = document.querySelector(".dismiss-notice");
      if (urgentNotice) {
        urgentNotice.classList.remove("hide");

        if (dismissNotice) {
          dismissNotice.addEventListener("click", function () {
            urgentNotice.classList.add("hide");
            Cookies.set("dismissed_urgent_notice", "true", {
              domain: window.location.host, //"dunmoreadventure.com", 
            });
          });
        }
      }

      const modalUrgentNotice = document.querySelector(
        ".o-modal-urgent-notice"
      );
      if (modalUrgentNotice) {
        $("#urgentNoticeModal").foundation("open");

        if (dismissNotice) {
          dismissNotice.addEventListener("click", function () {
            $("#urgentNoticeModal").foundation("close");
            Cookies.set("dismissed_urgent_notice", "true", {
              domain: window.location.host, //"dunmoreadventure.com",
            });
          });
        }
      }
    }
  },
};
export default JsNotice;
