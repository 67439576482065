import $ from 'jquery';
// import 'babel-polyfill';
import 'foundation-sites';
import 'slick-carousel';
// import 'lazysizes';
// import pictureFill from 'picturefill';
// import objectFitImages from 'object-fit-images';
// import 'lodash';
import Cookies from 'js-cookie';
// Project Modules
import JsGridder from './modules/gridder';
import JsReviews from './modules/google-reviews';
// Project Partials
// import Object from '../../src/components/03-ui/component-folder/component-file-name';
import JsNotice from './components/02-objects/urgent-notice/urgent-notice';
import JsCookieNotice from './components/02-objects/cookie-notice/cookie-notice';
import JsSlider from './components/03-ui/slider/slider';
// require('./vendor/jquery.gridder.min.js');

window.$ = $;

// dom content ready event
window.addEventListener('DOMContentLoaded', () => {
	// Init foundation on doc ready
	$(document).foundation();
	// Object Fit Polyfill
	// https://github.com/bfred-it/object-fit-images
	// objectFitImages();
	// Picture Polyfill
	// http://scottjehl.github.io/picturefill/
	// pictureFill();
	// Initialise imported js (this would be js required for every page, globally, i.e. headers
	// Object.init();
	JsNotice.init(Cookies);
	JsCookieNotice.init();
	JsSlider.init();
	// Init imported modules
	JsGridder.init();
	JsReviews.init();
	// Conditionally import and initialise additional chunks
	// See @ https://webpack.js.org/guides/code-splitting/ && https://sebastiandedeyne.com/posts/2018/code-splitting-with-laravel-mix
	// Depending on the page, these additional components may or may not be needed.
});
