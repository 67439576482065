function loadError(oError) {
  throw new URIError(`The script ${oError.target.src} didn't load correctly.`);
}

// https://developer.mozilla.org/en-US/docs/Web/API/HTMLScriptElement#dynamically_importing_scripts
function loadScript(url, onloadFunction) {
  const newScript = document.createElement("script");
  newScript.onerror = loadError;
  if (onloadFunction) {
    newScript.onload = onloadFunction;
  }
  document.body.appendChild(newScript);
  newScript.src = url;
}

// https://stackoverflow.com/questions/1199352/smart-way-to-truncate-long-strings
function truncate( str, n, useWordBoundary ){
  if (str.length <= n) { return str; }
  const subString = str.substr(0, n-1); // the original check
  return (useWordBoundary
    ? subString.substr(0, subString.lastIndexOf(" "))
    : subString) + "&hellip;";
};

const JsReviews = {
  init () {
    if (!document.querySelector('.google-reviews-wrapper')) {
      // No reviews wrapper on page, so don't load reviews
      return;
    }

    if (typeof google === 'object' && typeof google.maps === 'object') {
      // Google Maps API already loaded, so load reviews
      this.loadGoogleReviews();
    } else if (window.googleMapsApiUrl) {
      // Google Maps API not loaded, so load it and then load reviews
      loadScript( window.googleMapsApiUrl, this.loadGoogleReviews );
    }
  },

  // Google reviews (Pulls 5 most helpful reviews from Google)
  loadGoogleReviews() {
    const placesAPIKey = 'AIzaSyAxg8sVrdahfj-3_wUToxyKgZYioTA4Gqg';
    const henPartyPlaceId = 'ChIJLzzhP13CQkgRZ6G6IJHiotc';
    var request = {
      placeId: henPartyPlaceId
    };
    let map = new google.maps.Map(document.getElementById("hiddenReviewMap"), {
      center: {lat: 52.168402, lng: -7.136458},
      zoom: 14
    });
    var service = new google.maps.places.PlacesService(map);
    // var service = new google.maps.places.PlacesService(document.getElementById('#google-reviews-wrapper'));
    service.getDetails(request, function(place, status) {
      if (status == google.maps.places.PlacesServiceStatus.OK) {
        // console.log(place.reviews);
        if ( $('.google-reviews-list').length ) {
          // console.log("List is here");
          $.each(place.reviews, function(i, review) {
            $('.google-reviews-list').append(
              `
                <div class="cell">
                  <div class="o-card o-card--review">
                    <div class="o-card-content">
                      <div class="o-card__text">
                        ${review.text}
                      </div>
                      <h3 class="o-card__name"><a href="${review.author_url}">${review.author_name}</a></h3>
                      <a href="https://www.google.com/search?ei=uu5yX5PhNa7osAf306eACA&q=hen+party.ie+google+reviews&oq=hen+party.ie+google+reviews&gs_lcp=CgZwc3ktYWIQAzoECAAQR1CAR1joSWCCTWgAcAJ4AIABO4gBoQGSAQEzmAEAoAEBqgEHZ3dzLXdpesgBCMABAQ&sclient=psy-ab&ved=0ahUKEwiTpuzZ943sAhUuNOwKHffpCYAQ4dUDCAw&uact=5#lrd=0x4842c25d3fe13c2f:0xd7a2e29120baa167,1,,," class="o-card-view-all">
                        - VIEW ALL
                      </a>
                    </div>
                  </div>
                </div>
              `
            );
          });
        }
        if ( $('.google-review-single').length ) {
          // console.log("Single is here");
          let randomReview = place.reviews[Math.floor(Math.random() * place.reviews.length)];
          let reviewText = truncate( randomReview.text, 260, true );
          $('.google-review-single').append(
            `
              <div class="o-card o-card--review">
                <div class="o-card-content">
                  <h2 class="o-card__title">HenParty.ie Reviews</h2>
                  <div class="o-card__text">
                    ${reviewText}
                  </div>
                  <h3 class="o-card__name"><a href="${randomReview.author_url}">${randomReview.author_name}</a></h3>
                  <a href="${window.location.protocol}//${document.location.host}/testimonials" class="o-card-view-all">
                    - VIEW MORE
                  </a>
                </div>
              </div>
            `
          );
        }
      }
    });
  }
};
export default JsReviews;